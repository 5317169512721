#LeftWing {
  animation: leftWin 0.8s ease-in-out infinite alternate;
  transform-origin: center;
}

#RightWing {
  animation: leftWin 0.8s ease-in-out infinite alternate;
  transform-origin: center;
}

@keyframes leftWin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(60deg);
  }
}
